<template>
  <el-dialog
    title=""
    :visible.sync="mailtoMailDialogStatus"
    width="540rem"
    :show-close="false"
    class="dialog"
  >
    <i class="el-icon-close" @click="mailtoMailDialogStatus = false"></i>
    <div class="dialog_box flex-col">
      <div class="dialog_txt">
        公益广告投放，可在服务时间（周一至周六：<br>9:00-20:00）通过以下方式联系我们：
      </div>
      <div class="dialog_txt">
        <span>邮箱地址：</span>
        <a
          href="mailto: wenjie.huang@daping.cn"
          >wenjie.huang@daping.cn</a
        >
      </div>
      <div class="dialog_txt">
        <span>电话：</span>
        <span style="color: #2bb3e3">400-998-9272</span>
      </div>
      <span class="dialog_txt" style="margin-top:38rem"
        >我们将与合作伙伴协商后，提供优惠的投放方案。</span
      >
      <div slot="footer" class="dialog-footer flex-row justify-center">
        <div @click="mailtoMailDialogStatus = false">好的</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      mailtoMailDialogStatus: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    // 打开弹窗
    openDialog() {
      this.mailtoMailDialogStatus = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .el-icon-close {
    position: absolute;
    top: -135%;
    right: -142%;
    color: #fff;
    font-size: 36rem;
    cursor: pointer;
  }
  .dialog_box {
    .dialog_txt {
      font-size: 18rem;
      color: #252927;
      line-height: 25rem;
      text-align: left;
      &:nth-child(2),&:nth-child(3){
        margin-top: 6rem;
      }
      a {
        cursor: pointer;
        color: #2bb3e3;
        &:hover {
          color: #15a4d6;
        }
      }
    }
    .dialog-footer {
      width: 100%;
      margin-top: 35rem;
      div {
        width: 128rem;
        height: 42rem;
        line-height: 42rem;
        border-radius: 25rem;
        text-align: center;
        color: #2bb3e3;
        background: #ffffff;
        border-radius: 25rem;
        border: 1rem solid #32c3f6;
        font-size: 16rem;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
          color: #fff;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  z-index: 99999;
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 54rem 72rem 37rem;
}
</style>
